a.button {
    text-decoration: none;
}

.button.is-fb {
    background-color: #3b589e;
    color: #fff;
    &:hover {
        color: #eee;
    }
}

.button.is-google {
    background-color: #dc4a38;
    color: #fff;
    &:hover {
        color: #eee;
    }
}
